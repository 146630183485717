import React, { useState, useEffect } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'

import TableHead from '../../GlobalComponents/TableHead';
import ImageBox from '../ImageBox';


export default function ALieferstellen(props) {
    const [curent, setCurent] = useState('');
    const [tableData, setTableData] = useState([]);
    const [ImageOpen, setImageOpen] = useState(false);
    const [ImageUri, setImageUri] = useState([]);
    const [ImageDir, setImageDir] = useState('');

    function handleSelect(Name) {
        props._lieferSelectCall(Name)
        setCurent(Name)
    }
    function handleImage(item) {

        if (item.ImageUri !== null) {
                
            setImageDir('tour_images/' + item.Manifest + '/'// + item.LieferID + '/' 
            ); 

            setImageUri(item.ImageUri)
            setImageOpen(true)
        }
    }
    function ImageCall() {
        setImageOpen(false)
    }
    return (
        <h2>
            <Button h='25px' w='180px' borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" onClick={() => [handleImage(props.item)]}>
                <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
                    <Text>{props.item.Recipient}</Text>
                </Box>
            </Button>
            {ImageUri.length !== 0 ? (
                <ImageBox isOpen={ImageOpen} ImageCall={ImageCall} ImageDir={ImageDir} ImageUri={ImageUri} server={props.server} curentReci={props.item.Recipient} />
            ) : (null)}
        </h2>
    );
}